<template>
    <div
        class="relative flex w-full flex-col items-center justify-center md:mb-8"
    >
        <!-- Semi-circle with dashes -->
        <div
            class="absolute top-7 z-10 flex w-full items-center justify-center"
            style="
                -webkit-mask-image: linear-gradient(
                    to bottom,
                    black 50%,
                    transparent 100%
                );
                mask-image: linear-gradient(
                    to bottom,
                    black 50%,
                    transparent 100%
                );
            "
        >
            <BizMobileArch class="block md:hidden" />
            <BizDesktopArch class="hidden md:block" />
        </div>
        <!-- Invite Button -->
        <div class="z-20 flex w-full flex-col items-center justify-center">
            <div
                class="flex w-fit cursor-pointer flex-row items-center gap-2 rounded-full bg-neutral-1000 px-8 py-4 font-400 text-[18px] text-neutral-50 outline-neutral-1000 hover:bg-neutral-700 active:bg-neutral-800"
                @click="
                    () => {
                        emits('showInfoModal')
                    }
                "
            >
                <IconMapTrifold
                    v-if="buttonIcon === 'IconMapTrifold'"
                    class="size-6 mb-0.5 text-neutral-50"
                />
                <IconTelegram
                    v-if="buttonIcon === 'IconTelegram'"
                    class="size-4"
                /><span>{{ buttonText }}</span>
            </div>
        </div>

        <!-- Description -->
        <div
            class="relative max-w-64 py-4 text-center text-base text-neutral-950/60"
        >
            Get started in less than 5 minutes
        </div>
    </div>
</template>
<script setup lang="ts">
import { defineEmits } from 'vue'

withDefaults(
    defineProps<{
        buttonText?: string
        buttonIcon?: string
    }>(),
    {
        buttonText: 'Request Invite',
        buttonIcon: 'IconTelegram',
    }
)

const emits = defineEmits(['showInfoModal'])
</script>
